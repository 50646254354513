html {
  // height: 100%;
  padding-top: 110px;
}

body {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg,
    #ed35fe 0%,
    #a617ff 25%,
    #6e4cf7 51%,
    #f978f2 100%
  );
  -webkit-animation: AnimateBG 10s ease infinite;
  animation: AnimateBG 10s ease infinite;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body::-webkit-scrollbar {
  display: none;
}

#link:hover {
  cursor: pointer;
}

#buttons:hover {
  cursor: pointer;
}

#buttonsDL:hover {
  cursor: pointer;
}

#buttonsDL2:hover {
  cursor: pointer;
}

.App {
  height: 100%;
}

@media only screen and (max-width: 1100px) {
  #feature1 {
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    align-content: center !important;
    margin-bottom: 10 !important;
    margin-top: 0 !important;
    z-index: 130 !important;
    margin-right: 0 !important;
  }

  #feature2 {
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    align-content: center !important;
    margin-bottom: 10 !important;
    margin-top: 0 !important;
    z-index: 130 !important;
    margin-right: 0 !important;
  }

  #feature3 {
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    align-content: center !important;
    margin-bottom: 10 !important;
    margin-top: 0 !important;
    z-index: 130 !important;
    margin-right: 0 !important;
  }

  #feature4 {
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    align-content: center !important;
    margin-bottom: 10 !important;
    margin-top: 0 !important;
    z-index: 130 !important;
    margin-right: 0 !important;
  }

  #feature5 {
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    align-content: center !important;
    margin-bottom: 10 !important;
    margin-top: 0 !important;
    z-index: 130 !important;
    margin-right: 0 !important;
  }

  #feature6 {
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    align-content: center !important;
    margin-bottom: 10 !important;
    margin-top: 0 !important;
    z-index: 130 !important;
    margin-right: 0 !important;
    font-size: 1.1vw !important;
  }

  .titles {
    text-align: center !important;
    font-weight: 800 !important;
    font-size: 40px !important;
    letter-spacing: 2px !important;
    color: white !important;
    text-decoration: none !important;
    z-index: 130 !important;
    margin-right: 0 !important;
    margin-left: 5% !important;
    justify-content: center !important;
    align-items: center !important;
    text-shadow: 1px 1px 5px rgb(0, 0, 0, 0.4) !important;
  }

  .titles2 {
    text-align: center !important;
    font-weight: 800 !important;
    font-size: 40px !important;
    letter-spacing: 2px !important;
    color: white !important;
    text-decoration: none !important;
    z-index: 130 !important;
    margin-right: 0 !important;
    margin-left: 0% !important;
    justify-content: center !important;
    align-items: center !important;
    text-shadow: 1px 1px 5px rgb(0, 0, 0, 0.4) !important;
  }

  .titles3 {
    text-align: center !important;
    font-weight: 800 !important;
    font-size: 40px !important;
    letter-spacing: 2px !important;
    color: white !important;
    text-decoration: none !important;
    z-index: 130 !important;
    margin-right: 0 !important;
    margin-left: 3% !important;
    justify-content: center !important;
    align-items: center !important;
    text-shadow: 1px 1px 5px rgb(0, 0, 0, 0.4) !important;
  }


  .titles6 {
    text-align: center !important;
    font-weight: 800 !important;
    font-size: 40px !important;
    letter-spacing: 2px !important;
    color: white !important;
    text-decoration: none !important;
    z-index: 130 !important;
    margin-right: 0 !important;

    margin-top: 0 !important;
    margin-left: 0% !important;
    justify-content: center !important;
    align-items: center !important;
    text-shadow: 1px 1px 5px rgb(0, 0, 0, 0.4) !important;
  }

  .blurbs {
    font-weight: 50 !important;
    font-size: 1.8rem !important;
    letter-spacing: 0.1 rem !important;
    margin-left: 8% !important;
    margin-right: 3% !important;
  }

  .blurbs2 {
    font-weight: 50 !important;
    font-size: 1.8rem !important;
    letter-spacing: 0.1 rem !important;
    margin-left: 7% !important;
    margin-right: 7% !important;
  }

  .image {
    margin-left: 3%;
    width: 88.5% !important;
  }

  .image2 {
    width: 90% !important;
    margin-left: 4%;
    // margin-right: 10%;
  }

  .image3 {
    width: 86.9% !important;
    margin-left: 5% !important;
  }

  .image4 {
    width: 89% !important;
    margin-left: 2.5%;
    // margin-right: 10%;
  }

  .image5 {
    width: 89.5% !important;
    margin-left: 4%;
    // margin-right: 10%;
  }

  #pressimages {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: '100%' !important;
    margin-bottom: 15 !important;
    // margin-left: 20;
    // background-color: red;
  }
  #members {
    z-index: 40000;
    display: flex;
    overflow: auto;
    align-items: center;
    // width: 100%;
    flex-direction: column;
    flex-wrap: 'wrap';
  }

  #MediumArticle {
    width: 70%;
  }

  #ProductHuntArticle {
    width: 70%;
  }

  #MediumArticle:hover {
    cursor: pointer;
  }

  #johnWidth {
    width: auto !important;
  }

  #featuresDiv{
    margin-left:0,
  }
}
